<template>
  <v-skeleton-loader
    type="card, article"
    :loading="$store.state.session.status === 'init'"
  >
    <back-toolbar :title="$t('navigation.myPoco')"></back-toolbar>
    <v-img
      v-if="windowHeight - 560 > 40"
      :src="require('@/assets/feedback.png')"
      cover
      :lazy-src="require('@/assets/feedback.png')"
      height="calc(calc(100vh - 560px) - env(safe-area-inset-top))"
    >
      <v-row
        style="height: calc(calc(100vh - 560px) - env(safe-area-inset-top))"
        justify="center"
        align="center"
      >
        <v-img
          :src="require('@/assets/img/pocoWallet.png')"
          contain
          :lazy-src="require('@/assets/img/pocoWallet.png')"
          :style="
            windowHeight - 560 < 163
              ? 'height: calc(calc(100vh - 560px) - env(safe-area-inset-top))'
              : 'height: 103px'
          "
        />
      </v-row>
    </v-img>
    <v-container>
      <v-list flat>
        <template v-for="(item, index) in $store.state.menu.myPoco">
          <v-list-item class="px-0" :key="index" :to="{ name: item.name }">
            <v-list-item-avatar
              ><v-img
                contain
                height="20"
                :src="require(`@/assets/navigation/${item.icon}`)"
                :class="$store.state.settings.dark ? 'filter' : ''"
              ></v-img
            ></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="Text-Dark-Text-1---18-Left-Align"
                >{{ $t(`myPocoNavigation.${item.name}`) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-icon large>mdi-chevron-right</v-icon>
          </v-list-item>
        </template>
      </v-list>
      <v-row justify="center" algin="center" class="mt-5">
        <v-col cols="12" class="pb-0">
          <div class="Heading-Dark-H4-18-Center">{{ $t("myPoco.rate") }}</div>
        </v-col>
        <v-col cols="12">
          <v-rating
            v-model="rating"
            background-color="#c1c7ca"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star-outline"
            length="5"
            size="45"
            color="#fdbd2f"
            class="mt-3 text-center"
          ></v-rating>
        </v-col>
      </v-row>
    </v-container>
  </v-skeleton-loader>
</template>
<script>
import BackToolbar from "@/components/app/common/BackToolbar";
export default {
  components: {
    BackToolbar,
  },
  computed: {
    windowHeight() {
      return window.innerHeight;
    },
    rating: {
      get() {
        return this.$store.state.profile.data.rating || 0;
      },
      set(val) {
        this.$store.dispatch("profile/setPOCORating", { rating: val });
      },
    },
  },
};
</script>
